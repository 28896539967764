import React from "react"
import "./hero.css"

import heroCta1_1 from "../../images/how-it-works/hero-cta-1.png"
import heroCta1_2 from "../../images/how-it-works/hero-cta-1_2.png"

import heroCta2_1 from "../../images/how-it-works/hero-cta-2.png"
import heroCta2_2 from "../../images/how-it-works/hero-cta-2_2.png"


import mobile1_1 from "../../images/how-it-works/mobile-1_1.png"
import mobile1_2 from "../../images/how-it-works/mobile-1_2.png"
// import mobile1_3 from "../../images/how-it-works/mobile-1_3.png"


import mobile2_1 from "../../images/how-it-works/mobile-2_1.png"
import mobile2_2 from "../../images/how-it-works/mobile-2_1.png"
// import mobile2_3 from "../../images/how-it-works/mobile-2_3.png"


import { isMobile } from "react-device-detect"


export default class HeroCTAExternal extends React.Component {

  state ={
    isMobile:false,
  }







  componentDidMount() {
    this.setRender()
    document.addEventListener("resize", this.setRender)
    window.addEventListener("scroll", this.handleScroll)
     this.handleScroll()
    // console.log('componentDidMount')
    let bool = window.matchMedia("only screen and (max-width: 760px)").matches;

    this.setState({
      isMobile: bool,

    })
  }

  componentWillUnmount(){
   
    document.removeEventListener("resize", this.setRender )
    window.removeEventListener("scroll", this.handleScroll)
  }


  setRender = () => {
    if (window.innerWidth < 1000){
      this.setState({
        render: "mobile"
      })
    }
      else{
        this.setState({
          render: "desktop"
        })
      }
    
  }


  handleScroll = () => {
    // console.log("scrolling")
    var e = document.getElementById("hero-cta-container")
    if (this.distanceCheck(e)) {
      // console.log(e+ "this is e")
      //e.setAttribute("style", "position:fixed;top:20px;")
    } else {
      e.setAttribute("style", "position:sticky;top:560px;")
    }
  }


  distanceCheck = element => {
    var top = window.getComputedStyle(element).top
    top = top.substring(0, top.length - 0)
    top = parseInt(top)
    if (window.scrollY > top) {
      // console.log("true")
      return true
    } else {
      // console.log("false")
      if(this.state.isMobile){
      document.getElementById('cta-links-mobile-1').src=mobile1_1;
      }else{
        document.getElementById('cta-links-1').src=heroCta1_1;
      }
      return false
    }
  }


  currentPage = (event) => {
   // console.log(event.target.id);
   if(event.currentTarget.id==="cta-links-1"){
    event.currentTarget.childNodes[0].childNodes[0].src = heroCta1_2;
    document.getElementById('cta-links-2').childNodes[0].childNodes[0].src=heroCta2_1;
   }
   if(event.currentTarget.id==="cta-links-mobile-1"){
    event.currentTarget.childNodes[0].childNodes[0].src = mobile1_2;
    document.getElementById('cta-links-mobile-2').childNodes[0].childNodes[0].src=mobile2_1;
   }
   if(event.currentTarget.id==="cta-links-2"){
    event.currentTarget.childNodes[0].childNodes[0].src = heroCta2_2;
    document.getElementById('cta-links-1').childNodes[0].childNodes[0].src=heroCta1_1;
   }
   if(event.currentTarget.id==="cta-links-mobile-2"){
    event.currentTarget.childNodes[0].childNodes[0].src = mobile2_2;
    document.getElementById('cta-links-mobile-1').childNodes[0].childNodes[0].src=mobile1_1;
   }
  }


// renderContent=()=>{
//   if (this.state.mobile==='true'){
//     console.log("mobil")
//   }
// }





render() {

  var desktopView = <img
  className="desktopHeroBackground"
  src={this.props.desktopHeroBackground}
  alt={this.props.alt}
></img>
 
  var mobileView = <img
  className={"mobileHeroBackground " + this.props.mobileImageClass}
  src={this.props.mobileHeroBackground}
  alt={this.props.alt}
></img>

if(this.state.render == "desktop"){
  var backgroundImage = desktopView
}
else{
  var backgroundImage = mobileView
}



  return (
    <div className="hero-outer" id="hero-outer">
      <div className={`row hero cta-hero hero-about-vascepa ${this.props.additionalClassName}`}>
       {backgroundImage}
        <div className="col-md-5 hero-content">
          <h1 style={{ background: this.props.textGradient }}
            className={"animationA " + this.props.h1Class} > {this.props.headline}</h1>
          <h2 className="animationB">{this.props.content}</h2>

          <div className="hero-cta-container" id="hero-cta-container" >
           

                 {
                   this.state.isMobile ?
                   <a id ="cta-links-mobile-1" target="_blank" href={this.props.url1} className="cta-links cta-link2" >
                    <div  className="hero-cta-gradient-container">
                    <img
                      
                      className="hero-cta hero-cta-1"
                      alt="Truth About Supplements"
                      src={mobile1_1}
                    />
                    <div className="hero-cta-text-bold"><span style={{whiteSpace:"nowrap", marginLeft:"0px",marginTop:"0px"}}>{this.props.buttonLabel} <span style={{marginTop:"0px", marginLeft:"0px"}} aria-hidden="true">&nbsp;&gt;</span></span></div>
                    </div>
                 </a>
                 :
                 <a  id ="cta-links-1"  target="_blank" href={this.props.url1} className="cta-links cta-link2" >
                   <div className="hero-cta-gradient-container">                   
                   <img
                    
                     className="hero-cta hero-cta-1"
                     alt="Truth About Supplements"
                     src={heroCta1_1}
                   />
                   <div className="hero-cta-text-bold"><span style={{whiteSpace:"nowrap", marginLeft:"0px",marginTop:"0px"}}>{this.props.buttonLabel} <span style={{marginTop:"0px", marginLeft:"0px"}} aria-hidden="true">&nbsp;&gt;</span></span></div>
                   </div>
                 </a>
                 }



            </div>
          </div>
        </div>
      </div>
    )
  }
}
