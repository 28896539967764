import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Modal from "../components/modal/modal"

import Hero from "../components/hero/hero"
import HeroCTAExternal from "../components/hero/hero-cta-external"
import RightCta from "../components/cta/right-bordered/cta"
import LeftCta from "../components/cta/left-bordered/cta"

import desktopHero from "../images/about-vascepa/desktopHero.png"
import mobileHero from "../images/about-vascepa/mobileHero.png"
import gradientOne from "../images/about-vascepa/gradientOne.png"
import ctaBackground from "../images/about-vascepa/ctaBackground.svg"
import drop from "../images/about-vascepa/drop.png"
import doctorPatient from "../images/about-vascepa/doctorMan.png"
import doctorPatientMobile from "../images/about-vascepa/doctorManMobile.png"
import playButton from "../images/about-vascepa/playButton.svg"

export default () => (
  <Layout>
    <SEO
      title="About VASCEPA® (icosapent ethyl) "
      keywords="about VASCEPA, what is VASCEPA"
      description="Learn more about VASCEPA® (icosapent ethyl) and how it works to protect your heart. Please see Indication and Important Safety Information."
    />
    <h1 class="d-none">VASCEPA gives your heart something to celebrate</h1> 
    <div className="play-wrapper">
      {/* <Modal
        identifier="video-modal"
        modalClass="play-button"
        modalContent={[
          <iframe
            className="modal-video"
            src="https://player.vimeo.com/video/458324401?autoplay=1?texttrack=es"
            width="100%"
            height="100%"
            frameborder="0"
            allow="autoplay"
            allowfullscreen
          ></iframe>,
        ]}
        
      >
        <img className="play-button" src={playButton}></img>
      </Modal> */}
      <HeroCTAExternal
        desktopHeroBackground={desktopHero}
        mobileHeroBackground={mobileHero}
        additionalClassName="no-background"
        h1Class="about-vascepa"
        alt="Image from the VASCEPA® (icosapent ethyl) TV commercial"
        headline={[
          "VASCEPA gives your heart added protection when taken with a statin",
        ]}
        content={[
          "That can mean groundbreaking protection for your heart. The future is getting clearer.",
          <br />,
          <br />,
          "Questions about VASCEPA? Our brochure has answers."
        ]}
        url1="https://amarincorp.com/docs/VAS-02344VascepaHCCPatientBrochure_CVLaunch_R1.pdf"
        buttonLabel="Download brochure"
        mobileImageClass="abt-vascepa-mobile"
        
      />
    </div>

    <RightCta
      colOne="5"
      colTwo="7"
      whiteborder="whiteborder"
      image={drop}
      video={true}
      mobileIframe={[
        <iframe
          src="https://player.vimeo.com/video/400606146?background=1"
          width="640"
          height="355"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
          className="mobile-iframe"
        ></iframe>,
      ]}
      iframe={[
        <iframe
          src="https://player.vimeo.com/video/400606112?background=1"
          width="640"
          height="442"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>,
      ]}
      background={gradientOne}
      headline="Learn how the single active ingredient in VASCEPA works to protect your heart."
      cta={["How VASCEPA works ", <span aria-hidden="true">&gt;</span>]}
      url="/about-vascepa/how-it-works"
    />

    <LeftCta
      ctaBackground={ctaBackground}
      colOne="5"
      colTwo="7"
      whiteborder="transparentborder"
      image={doctorPatient}
      mobileImage={doctorPatientMobile}
      alt="Doctor and patient"
      backgroundColor="#E7E8EA"
      headline="Learn about the VASCEPA safety profile."
      cta={["Safety and side effects ",<span aria-hidden="true">&gt;</span>]}
      headlineColor="#1D355E"
      ctaColor="#1D355E"
      url="/about-vascepa/safety-and-side-effects"
      contentSubClass="left"
      ctaSubClass="left"
      mobileImageClass="img-fullwidth img-autoHeight height-auto img-mobile"
      imgSM="mobile-height-150"
    />
  </Layout>
)
